import React, { useState } from "react"
import "./press-cards.scss"
import { pressCardsData } from "./help"
import ArrowLeft from "../../images/learnmore/arrowLeft-light.svg"
import { Accordion, Button } from "react-bootstrap"
import Ali from "../../images/about/shapeTeam/ali.png"
import John from "../../images/about/shapeTeam/john.png"
import Gordon from "../../images/about/shapeTeam/gordon.jpg"
import Craig from "../../images/about/shapeTeam/craig.jpg"
import Doug from "../../images/about/shapeTeam/doug2.jpg"
import Ken from "../../images/about/shapeTeam/ken.png"
import Nicola from "../../images/about/shapeTeam/nicola.jpg"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin"
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin) // (or in the top-level file in your React app)

const PressCardItem = ({ card }) => {
  const [cardStatus, setCardStatus] = useState(false)

  let behaviourSciencePressStructure = (card) => {
    return (
      <>
        <hr />
        <div
          className="pressContent"
          dangerouslySetInnerHTML={{
            __html: card?.content,
          }}
        />

        <div className="pressPersonFlex">
          <div className="pressPersonProfile">
            <div className="pressPersonProfileImg bg">
              <img src={Ali} alt="Ali" />
            </div>
            <h4>Ali Khan</h4>
            <p>Chief Executive Officer</p>
            <p>SHAPE</p>
          </div>
          <p
            className="pressPersonContent"
            dangerouslySetInnerHTML={{
              __html: card?.ali,
            }}
          />
        </div>
        <div className="pressPersonFlex">
          <div className="pressPersonProfile">
            <div className="pressPersonProfileImg bg john">
              <img src={John} alt="John" />
            </div>
            <h4>Dr. John Lang</h4>
            <p>Chief Scientific Officer</p>
            <p>SHAPE</p>
          </div>
          <p
            className="pressPersonContent"
            dangerouslySetInnerHTML={{
              __html: card?.john,
            }}
          />
        </div>
        <div className="pressPersonFlex">
          <div className="pressPersonProfile">
            <div className="pressPersonProfileImg">
              <img src={Gordon} alt="Gordon" />
            </div>
            <h4>Gordon Watson</h4>
            <p>Chairman, AXA Asia</p>
          </div>
          <p
            className="pressPersonContent"
            dangerouslySetInnerHTML={{
              __html: card?.gordon,
            }}
          />
        </div>
        <div className="pressPersonFlex">
          <div className="pressPersonProfile">
            <div className="pressPersonProfileImg">
              <img src={Ken} alt="Ken" />
            </div>
            <h4>Ken Hoskin</h4>
            <p>Head of APAC HR, Adyen</p>
            <p>(Formerly at Meta, Airbnb)</p>
          </div>
          <p
            className="pressPersonContent"
            dangerouslySetInnerHTML={{
              __html: card?.ken,
            }}
          />
        </div>
        <div className="pressPersonFlex">
          <div className="pressPersonProfile">
            <div className="pressPersonProfileImg">
              <img src={Nicola} alt="Nicola" />
            </div>
            <h4>Nicola Thompson</h4>
            <p>Non Executive Director</p>
            <p>(Formerly CEO MADE.com)</p>
          </div>
          <p
            className="pressPersonContent"
            dangerouslySetInnerHTML={{
              __html: card?.nicola,
            }}
          />
        </div>
        <div className="pressPersonFlex">
          <div className="pressPersonProfile">
            <div className="pressPersonProfileImg">
              <img src={Craig} alt="Craig" />
            </div>
            <h4>Craig Moss</h4>
            <p>EVP, Ethisphere</p>
          </div>
          <p
            className="pressPersonContent"
            dangerouslySetInnerHTML={{
              __html: card?.craig,
            }}
          />
        </div>
        <div className="pressPersonFlex">
          <div className="pressPersonProfile">
            <div className="pressPersonProfileImg">
              <img src={Doug} alt="Doug" />
            </div>
            <h4>Doug Hudson</h4>
            <p>Managing Partner, Braxton Capital</p>
          </div>
          <p
            className="pressPersonContent"
            dangerouslySetInnerHTML={{
              __html: card?.doug,
            }}
          />
        </div>
        <br />
        <hr />
        <br />
        <p
          className="pressContent about"
          dangerouslySetInnerHTML={{
            __html: card?.conclusion,
          }}
        />
        <p
          className="pressContent about links"
          dangerouslySetInnerHTML={{
            __html: card?.links,
          }}
        />
      </>
    )
  }
  let contentRef = React.useRef(null)
  let containerRef = React.useRef(null)
  let cardContainer = React.useRef(null)
  const timelineRef = React.useRef(null)

  const toggleAccord = () => {
    if (!(contentRef.current && containerRef.current)) return
    setCardStatus((t) => !t)

    timelineRef.current = gsap.timeline({
      paused: true,
      autoKill: false,
    })

    timelineRef.current.to(
      contentRef.current,
      {
        height: !cardStatus ? "auto" : 0,
        ease: "power1.inOut",
        duration: 0.5,
      },
      0
    )
    if (cardStatus && cardContainer.current) {
      gsap.to(window, {
        duration: 0.01,
        scrollTo: cardContainer.current,
        offsetY: -200,
      })
    }

    timelineRef.current.play()
  }
  console.log("rendering: ", cardStatus)
  return (
    <div className="pressCard">
      <div className="pressCardHeadingContent" ref={cardContainer}>
        <h5>Published</h5>
        <p className="pressCardDate">{card?.published}</p>
        <h1>{card?.heading}</h1>
        <p
          className={`pressCardPreview ${cardStatus ? "cardExpanded" : ""}`}
          dangerouslySetInnerHTML={{ __html: card?.preview }}
        />
      </div>
      <div className="pressContentWrapper">
        {card?.ali ? (
          <div ref={contentRef} className="pressContent restrictedHeight">
            {behaviourSciencePressStructure(card)}
          </div>
        ) : (
          <div
            ref={contentRef}
            className="pressContent restrictedHeight"
            dangerouslySetInnerHTML={{
              __html: `<div>${card?.content}</div>`,
            }}
          />
        )}
        <div
          ref={containerRef}
          className={`pressCardExpandBtn ${cardStatus ? "cardExpanded" : ""}`}
          onClick={() => toggleAccord()}
        >
          <img src={ArrowLeft} alt="Expand" />
        </div>
      </div>
    </div>
  )
}

const PressCards = () => {
  return (
    <div className="pressPageWrapper">
      <div className="pressCardsWrapper">
        {pressCardsData?.map((card, index) => {
          return <PressCardItem card={card} key={`${card.heading}-${index}`} />
        })}
      </div>
    </div>
  )
}

export default PressCards
