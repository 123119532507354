import React from "react"
import "./press.scss"
import SEO from "../components/seo"
import Layout from "../components/layout"
import PressCards from "../components/press-cards/press-cards"

const Press = () => {
  return (
    <Layout>
      <SEO title="Press" />
      <PressCards />
    </Layout>
  )
}

export default Press
